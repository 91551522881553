export default {
  en: {
    last7days: 'last 7 days',
    capitalInUSD: 'Your capital in USD',
    partnerShipAccountBalance: 'Partnership Account Balance USDT',
    partnershipAccountBalanceDexnet: 'Partnership Account Balance DEXNET Tokens',
    directPartners: 'Direct Partners',
    totalPartners: 'Total Partners',
    totalRevenueFromDirectPartners: 'Total Revenue From Direct Partners',
    weeklyRevenue: 'Weekly Revenue from Partners',
    totalRevenue: 'Total Revenue from Partners',
    totalTeamTurnover: 'Total Team Turnover',
    howMuchYouCanGet: 'How much would you get',
    myRefferalLink: 'My Refferal Link',
    myRefferalDexLink: 'My Refferal DEXNET Link',
    myRefferalLinkParnters: 'My Refferal Link for Partners',
    myRefferalLinkInvestors: 'My Refferal Link for Investors',
    programNotActivated: 'The affiliate program is not activated',
    activate: 'Activate',
    progress: 'Your progress',
    generalProgress: 'Your general progress',
    goal: 'Goal',
    level: 'My Level',
    totalRevenueGlobalPool: 'Total Revenue from Global Pool',
    totalRevenueDexPool: 'Total Revenue from DEXNET Pool',
    pending: 'Pending',
    waitVerification: 'Waiting for verification',
    waitingForActivation: 'Waiting for activation',
    calculateRevenue: 'Calculate Revenue',
    startupCapital: 'StartUp Capital',
    investmentPeriod: 'Investment period',
    rateInWeek: 'Rate in Week',
    placeholderStartup: 'Your Startup Capital in $',
    placeholderMonth: 'Max 60 month!',
    calculate: 'Calculate',
    join: 'join',
    date: 'Date',
    time: 'Time',
    platform: 'Platform',
    transaction: 'FIX One Token Transaction on Blockchain',
    audit: 'Smart Contract Audit',
    address: 'Smart Contract Address',
    tokenGrowth: 'FIX One Token Growth',
    tokenPrice: 'Token Price',
    myTransactionTitle: 'My Transaction',
    typeTransaction: 'TYPE',
    dollarsTransaction: 'AMOUNT',
    amountTransaction: 'AMOUNT',
    FIXTransaction: 'FIX Transaction',
    tokens: 'TOKENS',
    dataTransaction: 'DATA TRANSACTION',
    statusTransaction: 'STATUS',
    actionsTransaction: 'ACTIONS',
    created: 'Created',
    completed: 'Completed',
    depositTransaction: 'DEPOSIT',
    show: 'Show',
    topic: 'Topic',
    speaker: 'Speaker',
    everyThursday: 'Every Thursday',
    everyFriday: 'Every Friday',
    everyTuesday: 'Every Tuesday',
    igorTitle: 'The main live broadcast from ProfiXone Capital fund with the president of the company',
    elinaTitle: 'FIX GOLD is the world\'s first token with a physical guarantee',
    elenaTitle: 'PROFIXHOME and the Cryptohouse program Northern Cyprus',
    weeklyWebinars: 'Weekly Webinars',
    igorTime: '20:00 UTC+3',
    elinaTime: '18:00 UTC+3',
    elenaTime: '19:00 UTC+3',
    igorFullName: 'Igor Botnari',
    elinaFullName: 'Elia Moreva',
    elenaFullName: 'Elena Karpovskaya',
    comingSoon: 'coming soon',
    investment: 'Investments',
    EntranceToAcademy: 'Entrance to the Academy',
    statusAcademy: 'Status',
    myCollectionNFT: 'My Collection',
    more: 'More',
    bonus: 'Bonus',
    availableForWithdraw: 'Available for withdraw',
    Zoom: 'Zoom',
    'FIX Gold': 'FIX Gold',
    ProfiXHome: 'ProfiXHome'
  },
  ru: {
    last7days: 'за 7 дней',
    capitalInUSD: 'Ваш капитал в USD',
    partnerShipAccountBalance: 'Баланс Партнерского счета USDT',
    partnershipAccountBalanceDexnet: 'Баланс Партнерского счета DEXNET',
    directPartners: 'Личные Партнеры',
    totalPartners: 'Всего Партнеров',
    totalRevenueFromDirectPartners: 'Общий доход Личных Партнеров',
    weeklyRevenue: 'Недельный доход от Партнеров',
    totalRevenue: 'Общий доход от Партнеров',
    totalTeamTurnover: 'Общий оборот команды',
    howMuchYouCanGet: 'Сколько Вы могли бы получить',
    myRefferalLink: 'Моя реферальная ссылка',
    myRefferalDexLink: 'Моя реферальная DEXNET ссылка',
    myRefferalLinkParnters: 'Моя реферальная ссылка для партнеров',
    myRefferalLinkInvestors: 'Моя реферальная ссылка для инвесторов',
    programNotActivated: 'Партнерская программа не активирована',
    activate: 'Активировать',
    progress: 'Ваш прогресс',
    generalProgress: 'Ваш общий прогресс',
    goal: 'Цель',
    level: 'Мой уровень',
    totalRevenueGlobalPool: 'Всего заработано в Global Pool',
    totalRevenueDexPool: 'Всего заработано в DEXNET Pool',
    pending: 'В процессе',
    waitVerification: 'Ожидает подтверждения',
    waitingForActivation: 'Ожидает активации',
    calculateRevenue: 'Рассчитать Доход',
    startupCapital: 'Стартовый капитал',
    investmentPeriod: 'Период инвестирования',
    rateInWeek: 'Ставка в неделю',
    placeholderStartup: 'Ваш стартовый капитал в $',
    placeholderMonth: 'Максимум 60 месяцев!',
    calculate: 'Расчитать',
    join: 'присоединиться',
    date: 'Дата',
    time: 'Время',
    platform: 'Платформа',
    transaction: 'FIX One Токен Транзакации на Блокчейне',
    audit: 'Аудит Смарт Контрака',
    address: 'Адрес Смарт Контракта',
    tokenGrowth: 'FIX One Token Рост',
    tokenPrice: 'Token Цена',
    myTransactionTitle: 'Мои транзакции',
    typeTransaction: 'ТИП',
    dollarsTransaction: 'КОЛ-ВО',
    amountTransaction: 'КОЛ-ВО',
    FIXTransaction: 'FIX ТОКЕНЫ',
    tokens: 'ТОКЕНЫ',
    dataTransaction: 'ДАТА ТРАНЗАКЦИИ',
    statusTransaction: 'СТАТУС',
    actionsTransaction: 'ДЕЙТСВИЯ',
    created: 'Создана',
    completed: 'Выполнена',
    depositTransaction: 'ДЕПОЗИТ',
    show: 'Показать',
    topic: 'ТЕМА',
    speaker: 'СПИКЕР',
    everyThursday: 'Каждый Четверг',
    everyFriday: 'Каждую Пятницу',
    everyTuesday: 'Каждый Вторник',
    igorTitle: 'Главный прямой эфир от фонда ProfiXone Capital с президентом компании',
    elinaTitle: 'FIX GOLD - первый в мире токен с физической гарантией',
    elenaTitle: 'PROFIXHOME и программа Cryptohouse Северный Кипр',
    weeklyWebinars: 'Еженедельные вебинары',
    igorTime: '20:00 МСК',
    elinaTime: '18:00 МСК',
    elenaTime: '19:00 МСК',
    igorFullName: 'Игорь Ботнарь',
    elinaFullName: 'Эля Морева',
    elenaFullName: 'Елена Карповская',
    comingSoon: 'скоро',
    investment: 'Инвестиции',
    EntranceToAcademy: 'Вход в Академию',
    statusAcademy: 'Статус',
    myCollectionNFT: 'Моя Коллекция',
    more: 'Больше',
    bonus: 'Бонус',
    availableForWithdraw: 'Доступно для снятия',
    Zoom: 'Zoom',
    'FIX Gold': 'FIX Gold',
    ProfiXHome: 'ProfiXHome'
  },
  es: {
    last7days: 'Los últimos 7 días',
    capitalInUSD: 'Tu Capital en USD',
    partnerShipAccountBalance: 'Saldo de la cuenta de la Sociedad USDT',
    partnershipAccountBalanceDexnet: 'Saldo de la cuenta de la Sociedad DEXNET',
    directPartners: 'Socios Directos',
    totalPartners: 'Socios Totales',
    totalRevenueFromDirectPartners: 'Ingresos totales de Socios Directos',
    weeklyRevenue: 'Ingresos semanales de Socios',
    totalRevenue: 'Ingresos totales de Socios',
    totalTeamTurnover: 'Rotación Total del Equipo',
    howMuchYouCanGet: 'Cuánto podrías obtener',
    myRefferalLink: 'Mi Link de Referencia',
    myRefferalDexLink: 'Mi Link DEXNET de Referencia',
    myRefferalLinkParnters: 'Mi enlace de referencia para socios',
    myRefferalLinkInvestors: 'Mi enlace de referencia para inversores',
    programNotActivated: 'Programa de socios no activado',
    activate: 'Activar',
    progress: 'Su Valor',
    generalProgress: 'Su Valor',
    goal: 'Objetivo',
    level: 'Mi Nivel',
    totalRevenueGlobalPool: 'Ingresos totales de Global Pool',
    totalRevenueDexPool: 'Ingresos totales de DEXNET Pool',
    pending: 'Pendiente',
    waitVerification: 'A la espera de confirmación',
    waitingForActivation: 'esperando la activación',
    calculateRevenue: 'Calcular Ingresos',
    startupCapital: 'Capital Inicial',
    investmentPeriod: 'Periodo de Inversión',
    rateInWeek: 'Tasa en la Semana',
    placeholderStartup: 'Su Capital Inicial en $',
    placeholderMonth: 'Máximo 60 Meses!',
    calculate: 'Calcular',
    join: 'Entrar',
    date: 'Data',
    time: 'Hora',
    platform: 'Plataforma',
    transaction: 'Transacción de Token One FIX en Blockchain',
    audit: 'Auditoría de Contratos Inteligentes',
    address: 'Dirección de Contrato Inteligente',
    tokenGrowth: 'FIX One Token Crecimiento',
    tokenPrice: 'Token Precio',
    myTransactionTitle: 'Mis Transacciones',
    typeTransaction: 'TIPO',
    dollarsTransaction: 'CANTIDAD',
    amountTransaction: 'CANTIDAD',
    FIXTransaction: 'FIX Tokens',
    tokens: 'TOKENS',
    dataTransaction: 'Fecha de la Transacción',
    statusTransaction: 'Estado',
    actionsTransaction: 'Acciones',
    created: 'Creado',
    completed: 'Terminado',
    depositTransaction: 'DEPOSITAR',
    show: 'Show',
    topic: 'Tema',
    speaker: 'Orador',
    everyThursday: 'Todos Los Jueves',
    everyFriday: 'Todos los Viernes',
    everyTuesday: 'Todos Los Martes',
    igorTitle: 'Transmisión en vivo principal de ProfiXone Capital Fund con el presidente de la compañía',
    elinaTitle: 'FIX GOLD es el primer token de garantía física del mundo',
    elenaTitle: 'PROFIXHOME y el Programa cryptohouse Norte de Chipre',
    weeklyWebinars: 'Seminarios web semanales',
    igorTime: '20:00 UTC+3',
    elinaTime: '18:00 UTC+3',
    elenaTime: '19:00 UTC+3',
    igorFullName: 'Igor Botnari',
    elinaFullName: 'Elia Moreva',
    elenaFullName: 'Elena Karpovskaya',
    comingSoon: 'Próximamente',
    investment: 'Inversiones de capital',
    EntranceToAcademy: 'Entrada a la Academia',
    statusAcademy: 'Estado',
    myCollectionNFT: 'Mi colección',
    more: 'más',
    bonus: 'Bonus',
    availableForWithdraw: 'Disponible para retirar',
    Zoom: 'Zoom',
    'FIX Gold': 'FIX Gold',
    ProfiXHome: 'ProfiXHome'
  }
}
