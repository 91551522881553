<template>
  <div class="items-center gap-x-3 flex">
    <a class="flex items-center w-full 2sm:w-52 gap-x-2 bg-white dark:bg-gradient-to-r dark:from-[#292929] dark:to-[#171717] p-3 rounded-md" href="#">
      <FixOneTokenIcon />
      <div class="flex flex-col justify-center grow">
        <strong class="text-main-textSky dark:text-white text-xs hover:text-gray-400">FIX One</strong>
        <span class="text-main-textSkyLight dark:text-gray-600 text-xxs">FIX One Token</span>
      </div>
      <div class="flex flex-col justify-center items-end">
        <strong class="text-main-textSky dark:text-white text-xs">{{ tokenOne.price ?? t('loading') }}</strong>
        <strong class="text-green-600 text-xxs flex items-center gap-x-1">
          <BIconCaretUpFill/>
          +{{ '1.0' }}%
        </strong>
      </div>
    </a>
    <a class="flex items-center w-full 2sm:w-52 gap-x-2 bg-white dark:bg-gradient-to-r dark:from-[#292929] dark:to-[#171717] p-3 rounded-md" href="#">
      <img class="h-5 w-5" src="/img/fixtwo.png" alt="">
      <div class="flex flex-col justify-center grow">
        <strong class="text-main-textSky dark:text-white text-xs hover:text-gray-400">FIX Two</strong>
        <span class="text-main-textSkyLight dark:text-gray-600 text-xxs">FIX Two Token</span>
      </div>
      <div class="flex flex-col justify-center items-end">
        <strong class="text-main-textSky dark:text-white text-xs">{{ tokenTwo.price ?? t('loading') }}</strong>
        <strong class="text-green-600 text-xxs flex items-center gap-x-1">
          <BIconCaretUpFill/>
          +{{ '0.5' }}%</strong>
      </div>
    </a>
    <a class="flex items-center w-full 2sm:w-52 gap-x-2 bg-white dark:bg-gradient-to-r dark:from-[#292929] dark:to-[#171717] p-3 rounded-md" href="#">
      <img class="h-5 w-5" src="/img/icons/dex.svg" alt="">
      <div class="flex flex-col justify-center grow">
        <strong class="text-main-textSky dark:text-white text-xs hover:text-gray-400">DEXNET</strong>
        <span class="text-main-textSkyLight dark:text-gray-600 text-xxs">DEXNET Token</span>
      </div>
      <div class="flex flex-col justify-center items-end">
        <strong class="text-main-textSky dark:text-white text-xs">{{ tokenDex ? tokenDex.toFixed(4) : t('loading') }}</strong>
        <strong class="text-green-600 text-xxs flex items-center gap-x-1">
          <BIconCaretUpFill v-if="tokenDexGrow"/>
          <BIconCaretDownFill fill="red" v-else/>
          <span v-if="tokenDexGrow">+</span>
          <!-- <span v-else>-</span> -->
          <span :style="{color: !tokenDexGrow ? 'red' : false}">{{tokenDexChange.toFixed(1)}}%</span></strong>
      </div>
    </a>
    <!-- <a class="flex items-center w-full 2sm:w-52 gap-x-2 bg-white dark:bg-gradient-to-r dark:from-[#292929] dark:to-[#171717] p-3 rounded-md" href="#">
      <FixGoldTokenIcon />
      <div class="flex flex-col justify-center grow">
        <strong class="text-main-textSky dark:text-white text-xs hover:text-gray-400">FIX Gold</strong>
        <span class="text-main-textSkyLight dark:text-gray-600 text-xxs">FIX Gold Token</span>
      </div>
      <div class="flex flex-col justify-center items-end">
        <strong class="text-main-textSky dark:text-white text-xs">{{ tokenGold.price ?? t('loading') }}</strong>
        <strong class="text-green-600 text-xxs flex items-center gap-x-1">
          <BIconCaretUpFill/>
          +{{ '0.72' }}%</strong>
      </div>
    </a> -->
  </div>

</template>

<script setup>
import { BIconCaretUpFill, BIconCaretDownFill} from 'bootstrap-icons-vue'
import { useI18n } from 'vue-i18n'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import FixGoldTokenIcon from '@/components/icons/FixGoldTokenIcon.vue'
import FixOneTokenIcon from '@/components/icons/FixOneTokenIcon.vue'

const { t } = useI18n()
const store = useStore()
const tokenOne = computed(() => store.getters.getFixOneChangeRate)
const tokenGold = computed(() => store.getters.getFixGoldChangeRate)
const tokenTwo = computed(() => store.getters.getFixTwoChangeRate)
const tokenDex = computed(() => store.getters.getDexTokenPrice)
const tokenDexChange = computed(() => store.getters.getDexTokenChange)

const tokenDexGrow = computed(() => tokenDexChange.value >= 0 )

onBeforeMount(() => {
  store.dispatch('getPrice')
})
</script>

<style>

</style>
