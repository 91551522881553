<template>
  <div class="actionCell flex flex-col">
    <router-link v-if="!showView" :to="linkToTransaction" class="view_transactions text-orange-600">
      {{ t('viewTransaction') }}
    </router-link>
    <button v-if="showDeleteBtn(status, type)" class="bloc sm:absolute right-2">
      <XIcon class="w-6 h-6 text-red-600" @click="deleteTransaction(types[type])"/>
    </button>
  </div>
</template>

<script setup>
import {
  XIcon,
} from '@heroicons/vue/outline'
import axios from 'axios'
import { url } from '@/main'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  value: Number,
  status: Number,
  id: Number,
  type: Number
})
const emit = defineEmits(['deleteTransaction'])
const types = ref({
  0: 'deposit',
  1: 'withdraw',
  2: 'FIXTokenCheck',
  11: 'deposit',
  3: 'deposit',
  4: 'deposit',
  5: 'deposit',
  6: 'deposit',
  7: 'deposit',
  8: 'deposit',
  9: 'withdraw',
  10: 'withdraw',
  12: 'exchange',
  13: 'withdraw',
  14: 'exchange',
  15: 'deposit',
  16: 'exchange',
  17: 'api_key',
  18: 'api_key',
  24: 'deposit',
  25: 'validator',
  27: 'deposit',
  28: 'deposit',
  29: 'withdraw',
  30: 'withdraw'
})
const showView = computed(() => [3, 6, 7, 8, 9, 10, 19, 20].includes(props.type))
const deleteTransaction = (type) => {
  axios.delete(`${url}/api/payments/${type}/${props.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true }).then(() => {})
    .then(() => {
      emit('deleteTransaction')
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('tokenAuth')
        document.location.href = document.location.origin
      }
    })
}
const linkToTransaction = computed(() => (`/${types.value[props.type]}/${props.id}`))
const showDeleteBtn = computed(() => ((status, type) => ((status === 0 || status === 5) && (type !== 4 && type !== 5))))
</script>

<style scoped>

@media (max-width: 1000px) {
  .view_transactions {
    font-size: 12px;
  }

}

@media (max-width: 640px) {
  .actionCell{
    width: 100%;
  }
}
</style>
